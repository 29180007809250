
.a9s-annotation > .a9s-inner, .a9s-selection > .a9s-inner {
    stroke-width: 0.5;
    stroke: red;
}

.a9s-annotation > .a9s-outer, .a9s-selection > .a9s-outer {
    stroke-width: 2.5;
    stroke: red;
}

svg.a9s-annotationlayer .a9s-selection-mask {
    fill: rgba(0, 0, 0, 0.6);
}

.r6o-editor {
    z-index: 100;
    width: auto !important;
    line-height: 8px !important;
}

/*.r6o-widget.comment.editable {*/

/*}*/